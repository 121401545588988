import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import StructuredContentRenderer from "@src/components/StructuredContentRenderer"
import TrackedCTA from "@src/components/TrackedCTA"
import ReviewButton from "@src/components/ReviewButton"
import ItemRating from "@src/components/ItemRating"

import {
  CardHeading,
  CardImageWrapper,
  CardWrapper,
  CardContentWrapper,
  CtaWrapper,
  QuickFacts,
  CardProsCons,
} from "../cardSubComponents"

import styles from "./SoftwareProductCard.module.less"

const SoftwareProductCard = props => {
  const {
    imgAlt,
    imgSrc,
    reviewUrl,
    name,
    facts,
    placementDrivers,
    bottomLine,
    pros,
    cons,
    marketplaceEntity,
    starRating,
  } = props
  const { id, productType, product, institution, cta, monetizable } =
    marketplaceEntity

  const ctaButton = (
    <>
      {cta?.link && cta.link !== reviewUrl && (
        <TrackedCTA
          id={id}
          productType={productType}
          product={product}
          institution={institution}
          monetizable={monetizable}
          cta={cta}
          ctaType="Software Product Card CTA"
          href={cta.link}
          classNames={classNames("clickable", styles.ctaButton)}
        />
      )}
    </>
  )

  const prosCons = pros && cons && (
    <CardProsCons pros={pros} cons={cons} reviewUrl={reviewUrl} />
  )

  const whyWeLikeIt = bottomLine && (
    <StructuredContentRenderer>{bottomLine}</StructuredContentRenderer>
  )

  const driverByFacts = placementDrivers.filter(driver =>
    facts.some(fact => fact === driver.id)
  )

  return (
    <CardWrapper>
      <CardImageWrapper imgAlt={imgAlt} imgSrc={imgSrc} heading={name} />
      <CardHeading heading={name}>
        {(!!starRating || (starRating && reviewUrl)) && (
          <ItemRating
            displayNumber
            horizontal
            href={reviewUrl}
            rating={starRating}
          />
        )}
      </CardHeading>
      <CtaWrapper>
        <ReviewButton reviewUrl={reviewUrl} />
        {ctaButton}
      </CtaWrapper>
      <QuickFacts
        facts={facts}
        placementDrivers={driverByFacts}
        inlineDescription={false}
      />
      <CardContentWrapper whyWeLikeIt={whyWeLikeIt} prosCons={prosCons} />
    </CardWrapper>
  )
}

SoftwareProductCard.propTypes = {
  applyUrl: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  reviewUrl: PropTypes.string,
  name: PropTypes.node.isRequired,
  bottomLine: PropTypes.object,
  pros: PropTypes.arrayOf(PropTypes.object),
  cons: PropTypes.arrayOf(PropTypes.object),
  starRating: PropTypes.number,
  placementDrivers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.string,
      value: PropTypes.string.isRequired,
      tooltip: PropTypes.string,
    })
  ).isRequired,
  cta: PropTypes.shape({
    link: PropTypes.string,
  }),
  marketplaceEntity: PropTypes.shape({
    productType: PropTypes.any.isRequired,
    id: PropTypes.string,
    product: PropTypes.object,
    institution: PropTypes.object,
    monetizable: PropTypes.string,
    cta: PropTypes.shape({
      link: PropTypes.string,
    }),
  }).isRequired,
  facts: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default SoftwareProductCard
