import React from "react"
import PropTypes from "prop-types"

import { Title } from "@nerdwallet/react-typography"

import Table, {
  DriverCell,
  EntityCell,
  StarRatingCell,
} from "@src/components/Table"
import TrackedCTA from "@src/components/TrackedCTA"
import ReviewButton from "@src/components/ReviewButton"
import {
  formatCurrency,
  getApplyOrRedirectUrl,
  isSectionActive,
} from "@src/lib/helpers"

import styles from "./SoftwareTableSection.module.less"

const CtaButton = props => {
  const { marketplaceEntity, cta, reviewUrl, applyUrl } = props
  if (cta?.link) {
    return (
      <TrackedCTA
        id={marketplaceEntity.id}
        productType={marketplaceEntity.productType}
        product={marketplaceEntity.product}
        institution={marketplaceEntity.institution}
        monetizable={marketplaceEntity.monetizable}
        cta={cta}
        ctaType="Software Summary CTA"
        href={applyUrl}
      />
    )
  }
  if (reviewUrl) {
    return <ReviewButton reviewUrl={reviewUrl} />
  }
  return null
}

CtaButton.propTypes = {
  marketplaceEntity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    product: PropTypes.shape({}).isRequired,
    institution: PropTypes.shape({}).isRequired,
    productType: PropTypes.string.isRequired,
    monetizable: PropTypes.string,
  }),
  cta: PropTypes.shape({
    link: PropTypes.string,
    subtext: PropTypes.string,
    text: PropTypes.string,
    type: PropTypes.string,
    association: PropTypes.string,
  }),
  reviewUrl: PropTypes.string,
  applyUrl: PropTypes.string,
}

const TableLocal = ({ entries }) => {
  const COLUMNS = [
    {
      id: "bank",
      heading: "Product",
    },
    {
      id: "rating",
      heading: "Best for",
    },
    ...entries[0]?.placementDrivers.map(driver => {
      return { id: driver.id, heading: driver.label }
    }),
    { id: "learnMore", heading: "Learn more" },
  ]

  const data = entries.map(entry => {
    const {
      heading,
      marketplaceEntity,
      cta,
      applyUrl,
      reviewUrl,
      starRating,
      reviewLink,
      placementDrivers,
      categoryName,
    } = entry

    const entryData = {
      bank: (
        <EntityCell
          imgAlt={entry.imgAlt}
          imgSrc={entry.imgSrc}
          imgClassName={styles.image}
          text={heading}
          subNodes={
            <div className={styles.showOnMobileOnly}>
              <CtaButton
                marketplaceEntity={marketplaceEntity}
                cta={cta}
                reviewUrl={reviewUrl}
                applyUrl={applyUrl}
              />
            </div>
          }
        />
      ),
      rating: (
        <StarRatingCell
          rating={starRating}
          link={reviewLink}
          categoryName={categoryName && `Best for ${categoryName}`}
        />
      ),
      minOpeningDeposit: entry.minOpeningDeposit,
      learnMore: (
        <div className={styles.textCenter}>
          <CtaButton
            marketplaceEntity={marketplaceEntity}
            cta={cta}
            reviewUrl={reviewUrl}
            applyUrl={applyUrl}
          />
        </div>
      ),
    }

    placementDrivers.forEach(
      driver =>
        (entryData[driver.id] = driver.value ? (
          <DriverCell
            description={driver.description}
            tooltip={driver.tooltip}
            value={driver.value}
            valueTooltip={driver.valueTooltip}
          />
        ) : (
          "N/A"
        ))
    )

    return entryData
  })

  return <Table columns={COLUMNS} data={data} stickyHeader />
}

TableLocal.propTypes = {
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      reviewUrl: PropTypes.string,
      marketplaceEntity: PropTypes.shape({}),
      categoryName: PropTypes.string.isRequired,
      cta: PropTypes.shape({}),
      federallyInsuredAnnotation: PropTypes.string,
      type: PropTypes.string.isRequired,
      heading: PropTypes.string.isRequired,
      imgAlt: PropTypes.string,
      imgSrc: PropTypes.string.isRequired,
      monetizable: PropTypes.string,
      placementDrivers: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
          description: PropTypes.string,
          tooltip: PropTypes.any,
          valueTooltip: PropTypes.any,
        })
      ).isRequired,
      reviewLink: PropTypes.string,
      applyUrl: PropTypes.string,
      starRating: PropTypes.number,
      minOpeningDeposit: PropTypes.number,
    })
  ).isRequired,
}

const SoftwareTableSection = ({ title, sections, productDescription }) => {
  const entries = sections.filter(isSectionActive).map(section => {
    const { marketplaceEntity } = section
    return {
      marketplaceEntity: {
        id: marketplaceEntity.id,
        product: marketplaceEntity.product,
        institution: marketplaceEntity.institution,
        productType: marketplaceEntity.productType,
        monetizable: marketplaceEntity.monetizable,
      },
      categoryName: section.categoryName,
      cta: marketplaceEntity.cta,
      heading: marketplaceEntity.displayName,
      imgAlt: marketplaceEntity.image.alt,
      imgSrc: marketplaceEntity.image.sourceMedium,
      placementDrivers: marketplaceEntity.placementDrivers,
      reviewLink: marketplaceEntity.reviewLink,
      applyUrl: getApplyOrRedirectUrl(section),
      starRating: marketplaceEntity.starRating,
      minOpeningDeposit: formatCurrency(marketplaceEntity.minOpeningDeposit),
    }
  })

  return (
    <>
      <Title size="large" className={styles.title}>
        {title}
      </Title>

      <TableLocal
        title={title}
        entries={entries}
        productDescription={productDescription}
      />
    </>
  )
}

SoftwareTableSection.propTypes = {
  title: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      marketplaceEntity: PropTypes.shape({
        displayName: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  productDescription: PropTypes.string.isRequired,
}

SoftwareTableSection.defaultProps = {}

export default SoftwareTableSection
