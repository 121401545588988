import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { graphql } from "gatsby"

import { ContentImpression } from "@src/lib/impression"
import Type from "@nerdwallet/react-typography"

import AuthorsSection from "@src/components/AuthorsSection"
import Box from "@src/components/Box"
import DateFormat from "@src/components/DateFormat"
import ExpandableContent from "@src/components/ExpandableContent"
import FaqSection from "@src/components/FaqSection"
import Page, { Footer, Header, HeaderContent } from "@src/components/Page"
import RecapSection from "@src/components/roundup/RecapSection"
import StructuredContentRenderer from "@src/components/StructuredContentRenderer"

import { assignments } from "@src/lib/variations"
import { LocationVariantProvider } from "@src/hooks/useLocationVariant"
import { ApplyUrlProvider } from "@src/hooks/useApplyUrl"
import { useAnalyticsPageTrack } from "@src/hooks/useAnalyticsPageTrack"
import { getCanonicalLink } from "@src/lib/helpers"

import styles from "./roundup.module.less"
import SoftwareProductSection from "@src/components/roundup/SoftwareProductSection"
import SoftwareTableSection from "@src/components/roundup/SoftwareTableSection"
import useFeatureFlag from "@src/hooks/useFeatureFlag"

const isValidSection = section => !!section.marketplaceEntity

const RoundUp = ({ data, location }) => {
  const { smbRoundup: roundup } = data

  useAnalyticsPageTrack(roundup.contentId)
  const validSections = roundup.roundupSections.filter(isValidSection)
  const hideRecapSection = useFeatureFlag("hide-recap-section")

  const header = (
    <Header attributionTopic={roundup.attributionTopic} hideBanner />
  )

  const headerContent = (
    <HeaderContent
      title={roundup.title}
      date={roundup.displayDate}
      authors={roundup.authors}
      synopsis={
        <StructuredContentRenderer>
          {roundup.synopsis}
        </StructuredContentRenderer>
      }
      breadcrumb={roundup.breadcrumb}
      featuredImage={roundup.featuredMedia}
      headerSpace={9}
      assigningEditor={roundup.assigningEditor}
      hideAuthorByline={!roundup.showAuthorByline}
      hideEditorialStandards={!roundup.showEditorialDisclaimer}
    />
  )
  const footer = (
    <Footer attributionTopic={roundup.attributionTopic} hideBanner />
  )
  return (
    <ContentImpression contentId={roundup.contentId}>
      <LocationVariantProvider location={location} assignments={assignments}>
        <ApplyUrlProvider
          location={location}
          attributionTopic={roundup.attributionTopic}
        >
          <Page
            title={roundup.title}
            link={getCanonicalLink(roundup)}
            featuredImage={roundup.featuredMedia}
            seo={roundup.seo}
            jsonld={roundup.jsonld}
            header={header}
            headerContent={headerContent}
            footer={footer}
          >
            <main>
              <div className={styles.defaultMargin}>
                <ExpandableContent>
                  <StructuredContentRenderer>
                    {roundup.aboveSections}
                  </StructuredContentRenderer>
                </ExpandableContent>
              </div>
              {roundup.showSummaryTable && (
                <div className={styles.defaultMargin}>
                  <SoftwareTableSection
                    title={roundup.sectionTitle}
                    sections={validSections}
                  />
                </div>
              )}

              <div className={styles.defaultMargin}>
                <SoftwareProductSection sections={validSections} />
              </div>

              <div className={styles.defaultMargin}>
                <StructuredContentRenderer>
                  {roundup.belowSections}
                </StructuredContentRenderer>
              </div>

              <Box className={styles.defaultMargin}>
                <Type inline italic>
                  Last updated on <DateFormat timestamp={roundup.displayDate} />
                </Type>
              </Box>
              {!hideRecapSection && (
                <Box
                  className={classNames(
                    styles.defaultMargin,
                    styles.recapSection
                  )}
                >
                  <RecapSection
                    title={roundup.title}
                    sections={validSections}
                  />
                </Box>
              )}
              {roundup.faqs && (
                <Box className={styles.faqBox}>
                  <FaqSection faqs={roundup.faqs} />
                </Box>
              )}
            </main>

            <Box className={styles.defaultMargin}>
              <AuthorsSection authorBoxes={roundup.authorBox} />
            </Box>
          </Page>
        </ApplyUrlProvider>
      </LocationVariantProvider>
    </ContentImpression>
  )
}

RoundUp.propTypes = {
  data: PropTypes.shape({
    smbRoundup: PropTypes.shape({
      contentId: PropTypes.number.isRequired,
      customPath: PropTypes.string,
      breadcrumb: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        page: PropTypes.shape({
          title: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      jsonld: PropTypes.object,
      displayDate: PropTypes.string,
      attributionTopic: PropTypes.string,
      assigningEditor: PropTypes.object,
      authors: PropTypes.array,
      primaryCategory: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      showAuthorByline: PropTypes.bool,
      showEditorialDisclaimer: PropTypes.bool,
      showSummaryTable: PropTypes,
      synopsis: PropTypes.any,
      aboveSections: PropTypes.any,
      sectionTitle: PropTypes.string.isRequired,
      roundupSections: PropTypes.array.isRequired,
      belowSections: PropTypes.any,
      faqs: PropTypes.array,
      authorBox: PropTypes.array,
      seo: PropTypes.shape({ keyword: PropTypes.string }).isRequired,
      featuredMedia: PropTypes.shape({}).isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default RoundUp

export const query = graphql`
  query ($slug: String!) {
    smbRoundup(slug: { eq: $slug }) {
      contentId
      customPath
      title
      sectionTitle
      link
      jsonld
      displayDate
      attributionTopic
      assigningEditor {
        id
        name
        link
      }
      authors {
        id
        name
        link
        url: link
      }
      primaryCategory {
        name
      }
      breadcrumb {
        slug
        page {
          title
        }
      }
      showAuthorByline
      showEditorialDisclaimer
      showSummaryTable
      synopsis
      aboveSections
      roundupSections {
        categoryName
        categoryDescription
        bottomLine
        pros
        cons
        customReviewUrl
        cta {
          text
          subtext
          link
          type
        }
        marketplaceEntity {
          id
          status
          productType
          ... on SoftwareOfferV2 {
            prosBullets
            consBullets
            displayName
            paymentsCost
            paymentsDetails
            softwareCost
            starRating
            applyLink
            reviewLink
            monetizable
            image {
              source
              alt
              sourceMedium
            }
            cta {
              text
              subtext
              link
              type
            }
            placementDrivers {
              id
              label
              description
              value
              tooltip
            }
            institution {
              id
              name
            }
            product {
              id
            }
          }
        }
      }
      belowSections
      faqs {
        question
        answer
        answerSchema
      }
      authorBox: authors {
        id
        name
        link
        contactEmail
        twitter
        facebook
        linkedin
        marketplaceBio
        description
        avatarUrls {
          size
          url
        }
      }
      featuredMedia {
        croppedImages {
          sourceUrl
          width
          height
        }
      }
      seo {
        title
        description
        keyword
        facebook {
          title
          description
        }
        twitter {
          title
          description
        }
      }
    }
  }
`
