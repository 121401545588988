import React from "react"
import PropTypes from "prop-types"

import Category from "@src/components/roundup/Category"
import SoftwareProductCard from "@src/components/ProductCard/SoftwareProductCard"
import { isSectionActive } from "@src/lib/helpers"

const FACTS = ["payments", "monthly_fee", "price", "bonus"]

const MarketplaceProduct = ({ section }) => {
  const {
    id,
    applyLink,
    image,
    product,
    institution,
    productType,
    prosBullets,
    consBullets,
    reviewLink,
    displayName,
    cta,
    placementDrivers,
    monetizable,
    starRating,
  } = section.marketplaceEntity
  const { bottomLine, pros, cons, customReviewUrl } = section

  return (
    <SoftwareProductCard
      applyUrl={applyLink}
      reviewUrl={customReviewUrl || reviewLink}
      imgAlt={image.alt}
      imgSrc={image.source}
      name={displayName}
      facts={FACTS}
      placementDrivers={placementDrivers}
      starRating={starRating}
      bottomLine={bottomLine}
      pros={pros || prosBullets}
      cons={cons || consBullets}
      marketplaceEntity={{
        id,
        product,
        institution,
        productType,
        cta,
        monetizable,
      }}
    />
  )
}

MarketplaceProduct.propTypes = {
  section: PropTypes.shape({
    bottomLine: PropTypes.object,
    pros: PropTypes.arrayOf(PropTypes.object),
    cons: PropTypes.arrayOf(PropTypes.object),
    customReviewUrl: PropTypes.string,
    marketplaceEntity: PropTypes.shape({
      starRating: PropTypes.number,
      prosBullets: PropTypes.arrayOf(PropTypes.object),
      consBullets: PropTypes.arrayOf(PropTypes.object),
      image: PropTypes.shape({
        alt: PropTypes.string.isRequired,
        source: PropTypes.string.isRequired,
      }).isRequired,
      displayName: PropTypes.any.isRequired,
      applyLink: PropTypes.string.isRequired,
      reviewLink: PropTypes.string.isRequired,
      monetizable: PropTypes.string,
      cta: PropTypes.shape({
        link: PropTypes.string,
      }),
      productType: PropTypes.any.isRequired,
      placementDrivers: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
          tooltip: PropTypes.string.isRequired,
        })
      ).isRequired,
      productLabel: PropTypes.string,
      id: PropTypes.string,
      product: PropTypes.shape({
        id: PropTypes.string,
      }),
      institution: PropTypes.shape({
        id: PropTypes.string,
      }),
    }).isRequired,
  }).isRequired,
}

const SoftwareProductSection = ({ sections }) => {
  const categories = [
    ...new Set(sections.map(({ categoryName }) => categoryName)),
  ]

  return (
    <section>
      {categories.map(category => {
        const categorySections = sections.filter(
          section =>
            section.categoryName === category && isSectionActive(section)
        )
        return (
          <Category
            key={category}
            category={category}
            sections={categorySections}
            marketplaceProductComponent={MarketplaceProduct}
          />
        )
      })}
    </section>
  )
}

SoftwareProductSection.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      categoryName: PropTypes.string.isRequired,
      marketplaceEntity: PropTypes.shape({}).isRequired,
    })
  ).isRequired,
}

export default SoftwareProductSection
